var siteHeader = $("#siteHeader");
var mobileNavButton = $("#mobileNavButton");
var siteHeaderNav = $("#siteHeaderNav");
var panelOverlay = $(".header-nav--overlay");
var lgScreenWidth = 1024;

// activate tabindex on menu items
if ($(window).width() >= lgScreenWidth) {
  activateTabs(siteHeaderNav);
}

$(window).on('resize', function(){
  if ($(window).width() >= lgScreenWidth) {
    activateTabs(siteHeaderNav);
  }
});

// mobile menu show/hide
mobileNavButton.on("click", function() {
  let isActive = $(this).hasClass("active") && siteHeaderNav.hasClass("active");

  if (!isActive) 
  {
    $(this).addClass("active");
    $(this).attr("aria-expanded", "true");
    siteHeader.addClass("active");
    siteHeaderNav.addClass("active");
    siteHeaderNav.find("a").attr("tabindex", 0);
    panelOverlay.addClass("active");
  } 
  else 
  {
    $(this).removeClass("active");
    $(this).attr("aria-expanded", "false");
    siteHeader.removeClass("active");
    siteHeaderNav.removeClass("active");
    siteHeaderNav.find("a").attr("tabindex", -1);
    panelOverlay.removeClass("active");
  }
});

// close all menus on overlay click
panelOverlay.on("click", function() {
  siteHeader.removeClass("active");
  mobileNavButton.removeClass("active");
  siteHeaderNav.removeClass("active");
  $(this).removeClass("active");
});

// when shift-press the first menu item
$(".header-nav__main:first-child > a").keydown(function(e) {
  const code = e.keyCode || e.which;    
  // shift key and tab or space or down
  if ((e.shiftKey) && (code == 9 || code == 32 || code == 40)) 
  {
    siteHeader.removeClass("active");
    mobileNavButton.removeClass("active");
    siteHeaderNav.removeClass("active");
    panelOverlay.removeClass("active");
  }
});

// when press the last menu item
$(".header-nav__main:last-child > a").keydown(function(e) {
  const code = e.keyCode || e.which;    
  // no shift key but tab or space or down
  if ((!e.shiftKey) && (code == 9 || code == 32 || code == 40)) 
  {
    siteHeader.removeClass("active");
    mobileNavButton.removeClass("active");
    siteHeaderNav.removeClass("active");
    panelOverlay.removeClass("active");
  }
});

function activateTabs(el) {
  el.find("a").attr("tabindex", 0);
}