// get all .first-letter paragraphs
var firstLetterParags = document.getElementsByClassName("first-letter");

if (typeof(firstLetterParags) != "undefined" && firstLetterParags != null) {
  // for each paragraph
  for (var i = 0; i < firstLetterParags.length; i++) {
    replaceFirstLetter(firstLetterParags[i]);
  }
}

function replaceFirstLetter(el) {
  // get the first letter and create a div node
  let firstLetter = el.innerHTML.charAt(0);
  let letter = document.createTextNode(firstLetter);
  // create a letter container div
  let containerDiv = document.createElement("div");
  containerDiv.classList.add("letter-container");
  // create a letter div
  let letterDiv = document.createElement("div");
  letterDiv.classList.add("letter");
  letterDiv.appendChild(letter);
  // create a shadow div
  let shadowDiv = document.createElement("div");
  shadowDiv.classList.add("shadow");
  // add shadow and letter to the new container div
  containerDiv.prepend(letterDiv);
  containerDiv.prepend(shadowDiv);
  // remove the first letter from para
  el.innerHTML = el.innerHTML.slice(1);
  // add the new container div to para
  el.prepend(containerDiv);
  // add class to its container (for text container height)
  el.parentNode.classList.add("first-letter-container");
}