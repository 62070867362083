// make the whole item clickable
$(".data-link").on("click", function() {
  var linkUrl = $(this).data("link");
  window.location.href = linkUrl;
});

// open social share panel in each entry card
$(".social-open-button").on("click", function() {
  $(this).parent().siblings(".social-share-panel, .social-share-panel-overlay").show();
});
// close social share panel in each entry card
$(".social-close-button").on("click", function() {
  $(this).parent(".social-share-panel").hide();
  $(this).parent(".social-share-panel").siblings(".social-share-panel-overlay").hide();
});